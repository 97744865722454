import { graphql } from "gatsby";
import * as React from "react";
import Swiper from "react-id-swiper";
import styled from "styled-components";

import { unwrapFirst } from "../util";
import {
  Layout,
  HelmetSeo,
  HeroCoverBackground,
  Hero,
  Button,
  Section,
  HorizontalContainer,
  Cols,
  Col,
  FormattedRichText,
  Modal,
} from "../components/legacy";
import COST_ILLUSTRATION from "../../img/pages/document-processing-ebook/cost_illustration.svg";
import TIME_ILLUSTRATION from "../../img/pages/document-processing-ebook/time_illustration.svg";
import WHO_ILLUSTRATION from "../../img/pages/document-processing-ebook/who_illustration.svg";
import HERO_BACKGROUND_2 from "../../img/ui/patterns/hero_background_2.svg";
import { Domain, Config } from "../constants";
import { Color, Dimen } from "../constants/legacy";
import { useForm } from "react-typed-form";
import { FieldText, FieldSelect, SubmitButton } from "../form/legacy";

type Props = {
  data: GatsbyTypes.EbookLandingPageQuery;
};

export const query = graphql`
  query EbookLandingPage {
    prismicEbookLandingPage {
      data {
        hero_title
        hero_sub_title {
          raw
        }
        hero_button_label

        what_heading
        what_content {
          raw
        }

        page_previews {
          page_preview_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
        }

        who_heading
        who_content {
          raw
        }

        document_heading
        document_content {
          raw
        }
        document_types {
          document_type_illustration {
            alt
            dimensions {
              width
              height
            }
            url
          }
          document_type_name
        }
        document_button_label

        ocr_heading
        ocr_content {
          raw
        }
        ocr_aside_heading
        ocr_aside_content
        ocr_aside_fine_print

        automation_heading
        automation_content {
          raw
        }
        automation_times_heading
        automation_times {
          automation_time_name
          automation_time_duration
        }

        cta_text
        cta_button_label

        modal_form_heading
        modal_form_button_label
        modal_success_heading
        modal_success_content {
          raw
        }
        modal_success_button_label
        modal_success_button_url

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function DocumentProcessingEbook({ data }: Props) {
  const page = data.prismicEbookLandingPage?.data;
  if (!page) throw new Error("page missing");

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);


  return (
    <Layout footerBackColor={Color.cyan}>
      <HelmetSeo {...page} />

      <Hero
        title={page.hero_title || ""}
        subTitle={page.hero_sub_title?.raw}
        button={
          <Button
            label={page.hero_button_label || "Download now"}
            onClick={() => setModalVisible(true)}
            color="cyan"
          />
        }
        customBackground={<HeroCoverBackground src={HERO_BACKGROUND_2} />}
      />
      <Section>
        <HorizontalContainer>
          <Cols>
            <Col flex={6}>
              <StyledH2>{page.what_heading}</StyledH2>
              <FormattedRichText render={page.what_content?.raw} />
            </Col>
            <Col flex={1}>
              <div style={{ height: 40 }} />
            </Col>
            <Col flex={4}>
              <StyledSwiperWrap>
                <Swiper
                  spaceBetween={140}
                  pagination={{ el: ".swiper-pagination", clickable: true }}
                >
                  {page.page_previews?.map((item, i) =>
                    !item ? (
                      <div />
                    ) : (
                      <StyledSlide key={i}>
                        <img src={item.page_preview_image?.url} />
                      </StyledSlide>
                    )
                  )}
                </Swiper>
              </StyledSwiperWrap>
            </Col>
          </Cols>
        </HorizontalContainer>
      </Section>

      <StyledDarkBlueSection>
        <HorizontalContainer>
          <Cols>
            <Col flex={6}>
              <StyledH2>{page.who_heading}</StyledH2>
              <FormattedRichText render={page.who_content?.raw} onDark />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 60,
                }}
              >
                <Button
                  label={"Download now"}
                  onClick={() => setModalVisible(true)}
                  color="cyan"
                />
              </div>
            </Col>
            <Col flex={1}>
              <div style={{ height: 60 }} />
            </Col>
            <Col flex={2}>
              <StyledCenterItem>
                <img src={WHO_ILLUSTRATION} />
              </StyledCenterItem>
            </Col>
          </Cols>
        </HorizontalContainer>
      </StyledDarkBlueSection>

      <Section>
        <HorizontalContainer>
          <StyledH2>{page.document_heading}</StyledH2>
          <StyledColumns>
            <FormattedRichText render={page.document_content?.raw} />
          </StyledColumns>

          <StyledDocumentTypes>
            {page.document_types?.map((item) =>
              !item ? null : (
                <StyledDocumentType key={item.document_type_name}>
                  <div>
                    <div className="img-wrap">
                      <img src={item.document_type_illustration?.url} />
                    </div>
                    <span>{item.document_type_name}</span>
                  </div>
                </StyledDocumentType>
              )
            )}
          </StyledDocumentTypes>

          <StyledCenterItem>
            <Button
              label={page.document_button_label || "Download now"}
              color="orange"
              onClick={() => setModalVisible(true)}
            />
          </StyledCenterItem>
        </HorizontalContainer>
      </Section>

      <StyledBlueSection>
        <HorizontalContainer>
          <Cols>
            <Col flex={7}>
              <StyledH2>{page.ocr_heading}</StyledH2>
              <FormattedRichText render={page.ocr_content?.raw} onDark />
            </Col>
            <Col flex={1}>
              <div style={{ height: 45 }} />
            </Col>
            <Col flex={8}>
              <StyledOcrAside>
                <img src={COST_ILLUSTRATION} />
                <StyledH2>{page.ocr_aside_heading}</StyledH2>

                <p>{page.ocr_aside_content}</p>

                <span>{page.ocr_aside_fine_print}</span>
              </StyledOcrAside>
            </Col>
          </Cols>
        </HorizontalContainer>
      </StyledBlueSection>

      <Section>
        <HorizontalContainer>
          <Cols>
            <Col flex={7}>
              <StyledH2>{page.automation_heading}</StyledH2>
              <FormattedRichText render={page.automation_content?.raw} />
            </Col>
            <Col flex={1}>
              <div style={{ height: 45 }} />
            </Col>
            <Col flex={8}>
              <StyledAutomationTimesTop>
                <img src={TIME_ILLUSTRATION} />
                <h3>{page.automation_times_heading}</h3>
              </StyledAutomationTimesTop>

              {page.automation_times?.map((item, i) =>
                !item ? null : (
                  <StyledAutomationTime
                    key={item.automation_time_name}
                    $orange={i === 0}
                  >
                    <span>{item.automation_time_name}</span>
                    <div style={{ width: [40, 178, 238, 308][i] }}>
                      {i > 0 && (
                        <strong>{item.automation_time_duration}</strong>
                      )}
                    </div>
                    {i === 0 && (
                      <strong>{item.automation_time_duration}</strong>
                    )}
                  </StyledAutomationTime>
                )
              )}
            </Col>
          </Cols>
        </HorizontalContainer>
      </Section>

      <StyledCyanSection>
        <HorizontalContainer>
          <StyledCtaHeading>{page.cta_text}</StyledCtaHeading>
          <StyledCenterItem>
            <Button
              label={page.document_button_label || "Download now"}
              color="darkBlue"
              onClick={() => setModalVisible(true)}
            />
          </StyledCenterItem>
        </HorizontalContainer>
      </StyledCyanSection>

      {modalVisible && (
        <Modal
          onDismiss={() => {
            setModalVisible(false);
          }}
        >
          <iframe src="https://forms.wisetechglobal.com/shipamax/en-us/ebook-future-of-doc-processing/" width="100%" height="850" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
        </Modal>
      )}
    </Layout>
  );
}

const StyledSwiperWrap = styled.div`
  width: 285px;
  margin: 0 auto;
`;

const StyledSlide = styled.div`
  width: 285px;
  height: 405px;
`;

const StyledDarkBlueSection = styled(Section)`
  background-color: ${Color.darkBlue};
  color: #fff;
`;

const StyledH2 = styled.h2`
  /* font-size: 30px;
  font-weight: 700;

  @media (max-width: ${Dimen.breakpointDefault}px) {
    font-size: 24px;
  }
  */
`;

const StyledColumns = styled.div`
  columns: 2;

  @media (max-width: 900px) {
    columns: 1;
  }
`;

const StyledDocumentTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 60px -20px;

  @media (max-width: 820px) {
    margin-bottom: 35px;
  }
`;

const StyledDocumentType = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 0 20px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .img-wrap {
      background-color: #f8f8f8;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }

    span {
      width: 100%;
      font-size: 15px;
    }
  }

  @media (max-width: 820px) {
    width: 50%;

    > div {
      margin-bottom: 20px;
    }
  }
`;

const StyledCenterItem = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBlueSection = styled(Section)`
  background-color: ${Color.blue};
  color: #fff;
`;

const StyledOcrAside = styled.div`
  background-color: ${Color.lightGrey};
  color: ${Color.darkBlue};
  padding: 60px 40px 30px;

  img {
    display: block;
    margin: 0 auto 70px;
  }

  p {
    margin: 30px 0 45px;
    white-space: pre-line;
  }

  span {
    display: block;
    font-size: 15px;
  }

  @media (max-width: ${Dimen.breakpointDefault}px) {
    text-align: center;

    img {
      width: 140px;
      margin-bottom: 40px;
    }
  }
`;

const StyledAutomationTimesTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  img {
    margin-right: 47px;
  }

  h3 {
    margin: 0;
  }
`;

const StyledAutomationTime = styled("div") <{ $orange?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 16px;

  span {
    width: 165px;
    margin-right: -55px;
    position: relative;
    z-index: 1;
  }

  > strong {
    margin-left: 16px;
  }

  div {
    background-color: ${(p) => (p.$orange ? Color.orange : Color.cyan)};
    background: linear-gradient(
      90deg,
      ${Color.white},
      ${(p) => (p.$orange ? Color.orange : Color.cyan)} 50%
    );
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 20px;
  }
`;

const StyledCtaHeading = styled.h2`
  font-size: 40px;
  font-weight: 300;
  margin: 0 0 30px;
  text-align: center;

  @media (max-width: ${Dimen.breakpointDefault}px) {
    font-size: 30px;
  }
`;

const StyledCyanSection = styled(Section)`
  background-color: ${Color.cyan};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
`;

const StyledFormSpacer = styled.div`
  height: 20px;
`;
